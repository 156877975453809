import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { ReduxSlice } from "../../features";
import type { TyreHotelsState } from "./tyre-hotels.types";
import type { WorkOrderTyreHotel } from "../../../types";
import type { SocketEventPayload } from "../../services";

/**
 * Defines the snackbar initial state
 */
const initialState: TyreHotelsState = {
  tyreHotels: [],
};

export const tyreHotelsSlice = createSlice({
  name: ReduxSlice.TyreHotels,
  initialState,
  reducers: {
    updateTyreHotels: (state, action: PayloadAction<WorkOrderTyreHotel[]>) => {
      state.tyreHotels = action.payload;
    },
    _updateTyreHotelsLive: (
      state,
      action: PayloadAction<SocketEventPayload<WorkOrderTyreHotel>>,
    ) => {
      const { event, model } = action.payload;

      if (event === "CREATED") {
        state.tyreHotels = [...state.tyreHotels, model];
      }

      if (event === "UPDATED") {
        state.tyreHotels = state.tyreHotels.map((item) =>
          item.id === model.id ? { ...model } : item,
        );
      }

      if (event === "DELETED") {
        state.tyreHotels = state.tyreHotels.filter(
          (item) => item.id !== model.id,
        );
      }
    },
  },
});

export const tyreHotelsActionCreators = tyreHotelsSlice.actions;
export const tyreHotelsReducer = tyreHotelsSlice.reducer;
