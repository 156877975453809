import { Fragment, useEffect } from "react";

/**
 * Normalizes all css for maximum browser compatibility
 */
import CssBaseLine from "@mui/material/CssBaseline";

/**
 * Component Imports
 */
import { Router } from "./components/Router";

import { useActions } from "./hooks";

/**
 * Imports component specific css
 */
import "./App.css";

/**
 * Displays the component
 */
export const App: React.FC = () => {
  const { disconnectSocket } = useActions();

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <CssBaseLine />
      <Router />
    </Fragment>
  );
};
