import lodash from "lodash";
import type { HashMap } from "../../types";

/**
 * Converts array to hash map
 */
export const toHashMap = (array: any[], indexKey: string) => {
  /**
   * Initializes the map
   */
  const map: HashMap<any> = {};

  array.forEach((item) => (map[item[indexKey]] = item));

  return map;
};

export const fromSnakeToCamel: (data: any) => any = (data) => {
  if (lodash.isArray(data)) {
    return lodash.map(data, fromSnakeToCamel);
  }

  if (lodash.isObject(data)) {
    return lodash(data)
      .mapKeys((v: any, k: any) => lodash.camelCase(k))
      .mapValues((v: any, k: any) => fromSnakeToCamel(v))
      .value();
  }

  return data;
};
