import Echo from "laravel-echo";
import Pusher from "pusher-js";

import { fromSnakeToCamel } from "../../utils/common";

import type {
  TChannel,
  LaravelEcho,
  SocketEventPayload,
  SocketListenerOptions,
} from "./socket.types";

export class SocketClient {
  private socket!: LaravelEcho;
  private organizationId: number | undefined;
  private userId: number | undefined;
  private accountId: number | undefined;

  constructor(
    organizationId?: number | undefined,
    userId?: number | undefined,
    accountId?: number | undefined,
  ) {
    this.organizationId = organizationId;
    this.userId = userId;
    this.accountId = accountId;
  }

  public connect(token: string): Promise<void> {
    const {
      REACT_APP_SOCKET_HOST,
      REACT_APP_SOCKET_PORT,
      REACT_APP_SOCKET_CLUSTER,
      REACT_APP_DEFAULT_APP_KEY,
    } = process.env;

    if (
      !REACT_APP_SOCKET_HOST ||
      !REACT_APP_SOCKET_PORT ||
      !REACT_APP_SOCKET_CLUSTER ||
      !REACT_APP_DEFAULT_APP_KEY
    ) {
      throw new Error("Missing environment variables for socket configuration");
    }

    const socket = new Echo({
      authEndpoint: "/broadcasting/auth",
      broadcaster: "pusher",
      key: REACT_APP_DEFAULT_APP_KEY,
      wsHost: REACT_APP_SOCKET_HOST,
      wsPort: REACT_APP_SOCKET_PORT,
      wssPort: REACT_APP_SOCKET_PORT,
      forceTLS: false,
      encrypted: false,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      cluster: REACT_APP_SOCKET_CLUSTER,
      Pusher,
    });

    this.socket = socket;
    /** Temporary */
    window.Echo = socket;

    return new Promise<void>((resolve, reject) => {
      this.socket.connector.pusher.connection.bind("connected", () => {
        resolve();
      });

      this.socket.connector.pusher.connection.bind("error", () => {
        reject("error");
      });
    });
  }

  public disconnect(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.socket.disconnect();
      this.socket.leaveAllChannels();
      this.socket.options = {};
      //@ts-ignore Temporary
      window.Echo = null;
      resolve();
    });
  }

  public on(options: SocketListenerOptions): Promise<void> {
    const { channel, event, callback, withAuth } = options;

    return new Promise<void>((resolve, reject) => {
      if (!this.socket) return reject(new Error("No socket connection"));

      const _callback = (payload: SocketEventPayload<any>) => {
        const _payload = fromSnakeToCamel(payload);

        callback(_payload as SocketEventPayload<any>);
      };

      if (withAuth) {
        if (this.userId && this.organizationId) {
          const privateChannel = this.getPrivateChannel(channel);

          this.socket.private(privateChannel).stopListening(event, _callback);
          this.socket
            .private(privateChannel)
            .listen(event, (payload: SocketEventPayload<any>) => {
              _callback(payload);
              resolve();
            })
            .error((error: unknown) => {
              reject(error);
            });
        }
      } else {
        // public channels
      }
    });
  }

  public getPrivateChannel(channel: TChannel) {
    if (
      channel === "time-sheet.created" ||
      channel === "time-sheet.updated" ||
      channel === "general-account"
    ) {
      return `${channel}.${this.accountId}`;
    }

    return `${channel}.${this.organizationId}`;
  }

  public setOrganizationId(organizationId: number | undefined): this {
    this.organizationId = organizationId;

    return this;
  }

  public setUserId(userId: number | undefined): this {
    this.userId = userId;

    return this;
  }

  public setAccountId(accountId: number | undefined): this {
    this.accountId = accountId;

    return this;
  }

  public _printState(): void {
    console.log({ state: this });
  }
}
